<template>
    <card title="Notifications" class="profile-notifications" body-class="flex-wrap overflow-visible">
        <notifications v-if="hideNotifications" @hide="hideNotifications= false" :value="editNotifications" @save="updateTable" />

        <template slot="tools">
            <button class="btn" @click.prevent="editNotifications = null; hideNotifications = true">Add rule</button>
        </template>

        <v-table
            :id="'notifications'"
            :columns="columns"
            :panel="false"
            :tools="false"
            row-key="uuid"
            ref="notifications"
            endpoint="/api/notifications"
            sort-key="created_at"
            query-params
            no-cache
            :paginateValue="15"
            @row-click="rowClicked"
        >
            <template slot="cell" slot-scope="{row, col}">
                <span v-if="col.key === 'organization'">
                    {{ row.organization.name }}
                </span>

                <span v-else-if="col.key === 'details'">
                    {{ row.details.details || '' }}
                </span>

                <span v-else-if="col.key === 'template_id'">
                    {{ row.template_id }}
                </span>

                <span v-else-if="col.key === 'recipients'">
                    {{ row.recipients.map(item => { return item.full_name }).join(', ') }}
                </span>

                <span v-else-if="col.key === 'created_by'">
                    {{ row.created_by.name }}
                </span>

                <span v-else></span>
            </template>
        </v-table>
    </card>
</template>

<script>
import Notifications from './Notifications.vue';

export default {
    components: {
        Notifications
    },

    data() {
        return {
            hideNotifications: false,
            editNotifications: null,
            columns: [
                {
                    title: "Organization",
                    key: "organization",
                    toggled: true,
                    sortable: false,
                    width: "w-32",
                },
                {
                    title: "Details",
                    key: "details",
                    toggled: true,
                    sortable: false,
                    width: "w-32",
                },
                {
                    title: "Template id",
                    key: "template_id",
                    toggled: true,
                    sortable: false,
                    width: "w-32",
                },
                {
                    title: "Recipients",
                    key: "recipients",
                    toggled: true,
                    sortable: false,
                    width: "w-32",
                },
                {
                    title: "Created by",
                    key: "created_by",
                    toggled: true,
                    sortable: false,
                    width: "w-32",
                },
            ]
        }
    },
    methods: {
        rowClicked(value) {
            this.editNotifications = value.data;
            this.hideNotifications = true;
        },
        updateTable() {
            this.$refs['notifications'].table.fetchRows()
        },
    }
}
</script>
